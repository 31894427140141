















































import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { logoutDialog } from '@/components/Modals/Default/Dialog/Presets'

import {
  teamsStore,
  uiStore,
} from '@/store'
import { Contact } from '@tada-team/tdproto-ts'
import BaseEntityAvatar from '@/components/UI/BaseEntityAvatar.vue'

interface MenuOption {
  name: string;
  action: () => void;
  cypress: string;
  class?: string;
  id?: string;
}

const goal = (s: string) => window.goal('userControls', { userControls: s })

@Component({
  name: 'UserControls',
  components: {
    ServerDialog: () => import('@/components/UI/ServerDialog.vue'),
    BaseEntityAvatar,
  },
})
export default class UserControls extends Vue {
  showServerDialog = false

  @Getter profile!: Contact

  get showOpenConsoleOption (): boolean {
    if (!window.FEATURES.billing) return false
    return teamsStore.getters.teams.some(team => team.me.status === 'owner')
  }

  get profileOptions (): MenuOption[] {
    const showIntegrations = (
      !teamsStore.getters.isSingleGroupTeam() &&
      teamsStore.getters.currentTeam.me?.canManageIntegrations
    )

    const insertServerChanger = (
      window.isElectron &&
      window._electronFeatures &&
      window._electronFeatures.includes('serverChanger') &&
      !window._electronFeatures.includes('customBuild')
    )

    return [
      this.myProfileOption,
      ...this.showOpenConsoleOption ? [this.openConsoleOption] : [],
      ...showIntegrations ? [this.integrationsOption] : [],
      ...showIntegrations ? [this.calendarIntegrationsOption] : [],
      this.mobileAppsOption,
      this.loginViaQrOption,
      ...insertServerChanger ? [this.serverChangerOption] : [],
      this.logoutOption,
    ]
  }

  get myProfileOption (): MenuOption {
    return {
      name: this.$t('header.myProfile').toString(),
      action: () => {
        goal('Открыть «Мой профиль»')
        uiStore.actions.switchRightBarInstance({
          instance: 'contact-profile',
          payload: '',
        })
      },
      cypress: 'my-profile-btn',
    }
  }

  get openConsoleOption (): MenuOption {
    return {
      name: this.$t('common.personalAccount').toString(),
      action: () => {
        goal('Открыть «Личный кабинет»')

        if (this.$q.platform.is.electron) {
          location.href = `${location.origin}/console`
        } else {
          window.open(`${location.origin}/console`)
        }
      },
      cypress: 'open-console-btn',
      class: 'text-td-green-3',
      id: 'lk',
    }
  }

  get integrationsOption (): MenuOption {
    return {
      name: this.$t('modals.Integrations.title').toString(),
      action: () => {
        goal('Открыть «Управление интеграциями»')
        uiStore.actions.showModal({ instance: 'Integrations' })
      },
      cypress: 'manage-integrations-btn',
    }
  }

  get calendarIntegrationsOption (): MenuOption {
    return {
      name: this.$t('modals.CalendarIntegrations.title').toString(),
      action: () => {
        goal('Открыть «Интеграция с Календарями»')
        uiStore.actions.showModal({ instance: 'calendar-integration' })
      },
      cypress: 'calendar-integrations-btn',
    }
  }

  get mobileAppsOption (): MenuOption {
    return {
      name: this.$t('header.applications').toString(),
      action: () => {
        goal('Открыть «Скачать приложение»')
        uiStore.actions.showModal({ instance: 'MobileApps' })
      },
      cypress: 'apps-btn',
    }
  }

  get loginViaQrOption (): MenuOption {
    return {
      name: this.$t('header.loginViaQr').toString(),
      action: () => {
        goal('Открыть «Вход по QR-коду»')
        uiStore.actions.showModal({ instance: 'MobileLogin' })
      },
      cypress: 'login-via-qr-btn',
    }
  }

  get serverChangerOption (): MenuOption {
    return {
      name: this.$t('auth.change_server').toString(),
      action: () => {
        goal('Октрыть «Поменять сервер»')
        this.showServerDialog = true
      },
      cypress: 'change-server-btn',
    }
  }

  get logoutOption (): MenuOption {
    return {
      name: this.$t('header.exit').toString(),
      action: () => {
        goal('Логаут')
        logoutDialog()
      },
      cypress: 'logout-btn',
      id: 'logout',
    }
  }

  isDisabled (option: MenuOption) {
    if (option.id === 'lk' || option.id === 'logout') return false
    return teamsStore.getters.isCurrentTeamSuspended
  }

  private onBtnClick () {
    window.goal('userControls', { userControls: 'Открыть меню' })
  }
}
